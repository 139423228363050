import { Organization } from 'schema-dts';
import { SITE_BASE_URL } from '../../client/constants';

export function getOrganizationStructuredData(): Organization {
  return {
    '@type': 'Organization',
    name: 'Dove.it',
    legalName: 'Dove.it srl',
    url: SITE_BASE_URL,
    logo: 'https://cdn.dove.it/static/assets/logo.png',
    taxID: 'P.IVA 10461690967',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Via Borromei, 6',
      addressLocality: 'Milano',
      postalCode: '20123',
      addressCountry: 'Italy',
    },
    sameAs: [
      'https://www.facebook.com/doveitofficial',
      'https://twitter.com/Doveit_official',
      'https://www.instagram.com/dove.it_official/',
      'https://www.linkedin.com/company/doveit/',
      'https://it.trustpilot.com/review/dove.it',
      'https://www.wikidata.org/wiki/Q105093611',
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+39 02 87178289',
        contactType: 'customer service',
        email: 'info@dove.it',
        hoursAvailable: [
          {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            opens: '09:30',
            closes: '19:30',
          },
          {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Saturday'],
            opens: '09:30',
            closes: '13:30',
          },
        ],
      },
    ],
  };
}
